import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product/product.service';


@Component({
  selector: 'app-biz-market',
  templateUrl: './biz-market.component.html',
  styleUrls: ['./biz-market.component.scss']
})

export class BizMarketComponent implements OnInit {

  constructor(private service2 : ProductService, private router : Router) {}

  products = this.service2.getProducts(this.service2.isCategory6);

  ngOnInit(): void {
  }

  routeToProductDetail(id){
    this.router.navigate(['/product-detail',id]);
  }
}
