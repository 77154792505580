<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <!-- move styling to the css and make an icon copy in the assets folder
            rather than using this one -->
            <img style="height: 25px;" src="../../../../favicon.ico"> 
            <span>Online </span>Register
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                    <!-- (@sach) TODO: fix the transition to home, its jaggedy -->
                    <!-- (@sach) TODO: in the marketplace the nav bar links are white on white so can't see them -->
                <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            </ul>
        </div>
    </div>
</nav>