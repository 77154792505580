<section id="payment-failed" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Payment<span> Failed</span></h2>            
        </div>

        <section class="payment-failed-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="cta-content">
                    <div class="section-title mb-0">
                        <div style="background-color: #da251d;
                        text-align: center;
                        padding: 15px 0px 1px 0px;
                        margin: 30px 90px 30px 90px;
                        border-radius: 20px;
                        ">
                            <h4
                        style="color: antiquewhite;">Your payment has not been processed</h4>
                        </div>
                        
                        <h2 
                        style="-webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #463231;;"
                        >Sorry!</h2>
                        <p style="color: #463231;">Your payment has not successfully completed. Please try make your purchase again.</p>
                        
                        <p style="color: #463231;">
                            Our support team is always available to assist with your queries to help you and/or your business get the best possible service.
                        </p>   
                        
                        <a href="#contact" class="btn btn-primary">Contact Us</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>

<!-- <img src="https://img.icons8.com/clouds/100/000000/error.png"/> -->