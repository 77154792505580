import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {

  shoppingCart;

  constructor(private service : ShoppingCartService, private service2 : ProductService) {
    this.shoppingCart = service.getCartItems()
  }

  ngOnInit() {
  }

  selectProduct($event){
    var selectedProductId : string  = $event.srcElement.id
    var selectedProduct = this.basicRegistrationCombos
    .find(
      product => product.getId() == selectedProductId) ||
    this.packageRegistrationCombos
    .find(
      product => product.getId() == selectedProductId)
    this.service.addProduct(selectedProduct);
  }

  packageRegistrationCombos = this.service2.getProducts(this.service2.isCategory2);
  basicRegistrationCombos = this.service2.getProducts(this.service2.isCategory1);
}
