import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product/product.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  shoppingCart;

  constructor(private service : ShoppingCartService, private service2 : ProductService) { 
    this.shoppingCart = service.getCartItems(); // what is the use of this?? 
  }

  ngOnInit() {
  }

  // this concatenation should happen more elegantly
  products = this.service2.getProducts(this.service2.isCategory3).concat(this.service2.getProducts(this.service2.isCategory4));

  selectProduct($event){
    let selectedProductId : string  = $event.srcElement.id.split('BuyBtn')[0];
    let selectedProduct = this.products
    .find(
      product => product.getId() == selectedProductId);
    this.service.addProduct(selectedProduct);
  }
}
