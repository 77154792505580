<section id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2><span>Contact</span> Us</h2>
            <p>We are happy to be of service for all your queries. Please enter your details below and we will get back to you promptly.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #f="ngForm" (ngSubmit)="submit(f)">
                        <!-- this form need to have validation performed -->
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input ngModel name="name" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input ngModel name="email" type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input ngModel name="phone" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Title</label>
                                    <input ngModel name="title" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea ngModel name="message" class="form-control" cols="30" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- TODO: include online register as a watermark to these contact sections -->
            <div class="col-lg-5 col-md-12">
                <div class="col">
                    <div class="contact-info" style="margin-bottom: 10px;">
                        <div class="d-table">
                            
                            <div class="d-table-cell">
                                <ul>
                                    <li><i class="fas fa-map-marker-alt"></i> <span>Headquarters Location:</span>117 Minauch Road, Harmelia, Germiston 1429</li>
                                    <li><i class="far fa-envelope"></i> <a href="mailto: admin@onlineregister.co.za "><span>Email:</span>admin@onlineregister.co.za </a></li>
                                    <li><i class="fas fa-phone-alt"></i> <a href="tel:+27113015599"><span>Contact:</span>+27 (011) 301-5599</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
            
                    <div class="contact-info">
                        <div class="d-table">
                            
                            <div class="d-table-cell">
                                <ul>
                                    <li><i class="fas fa-map-marker-alt"></i> <span>Johannesburg CBD Location:</span>Shop 5 Ground Floor, Corner Main Street/Betty Street, Johannesburg CBD, 2000</li>
                                    <li><i class="far fa-envelope"></i> <a href="mailto:info@onlineregister.co.za"><span>Email:</span>info@onlineregister.co.za</a></li>
                                    <li><i class="fas fa-phone-alt"></i> <a href="tel:+27116149660"><span>Contact:</span>+27 (011) 614-9660</a></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>                
            </div>
        </div>
    </div>
</section>