<section id="about" class="about-area ptb-100" 
style="
margin-top: 20px;
margin-bottom: 20px;
padding-top: 40px;
padding-bottom: 20px;
">
    <div class="container">
        <div class="section-title">
            <h2>About <span>Online Register</span></h2>
            <p>
                We are one of the leading business registration service provider catering to South African Businesses and NPOs.
            </p>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We Are Online <span>Company Registrations</span></h2>
                        <p>We make it easy for you to register a business. Our experienced staff provide a quick, reliable and 
                            effective service, and we will answer all your questions about business registration.</p>
                    </div>
                    <ul class="features-list">
                        <!-- (@sach) TODO: highlight the first two options -->
                        <li><i class="fas fa-check"></i>Fast Company Registration</li>
                        <li><i class="fas fa-check"></i>Effective Business Consultation</li>
                        <li><i class="fas fa-check"></i>Easy Shelf Company Purchase</li>
                        <li><i class="fas fa-check"></i>Hassle-Free Payroll Services</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/signdoc-1.jpg" alt="about"
                    style="
                    border-radius: 10px;
                    ">
                    <div class="video-btn">
                        <a href="https://firebasestorage.googleapis.com/v0/b/fir-demo-f3039.appspot.com/o/WhatsApp%20Video%202020-12-28%20at%2010.00.51%20PM.mp4?alt=media&token=10eef3d3-84ca-4ba0-babe-5ece1b114e92" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>