import { Injectable } from '@angular/core';


// this includes a product service and shopping cart service
// @sach TODO: decompose this service into two individul service
@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  shoppingCart = [];

  addProduct(productId) {
    this.shoppingCart.push(productId);
  }

  // there has to be a better way of doing this
  removeProduct(product){
    const valueToRemove = product;
    const filteredItems = this.shoppingCart.filter(item => item !== valueToRemove)
    this.shoppingCart = filteredItems;
  }

  getCartItems(){
    return this.shoppingCart;
  }

  getCartTotal(){
    let total : number = 0;
    this.shoppingCart.forEach(product => {
      total = total + product.price;
    });
    return total;
  }

  constructor() { }
}
