import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success/payment-success.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary/order-summary.component';
import { BizMarketComponent } from './components/biz-market/biz-market.component';
import { BizProductDetailComponent } from './components/biz-market/biz-product-detail/product-detail/product-detail.component';

const routes: Routes = [
    // (@sach) TODO: transitions are not smooth
    {path: '', component: HomeComponent},
    {path: 'marketplace', component: MarketplaceComponent},
    {path: 'biz', component: BizMarketComponent},
    // (@sach) TODO: think about how to get the user's cart items to populate checkout 
    {path: 'checkout', component: CheckoutComponent},
    {path: 'customer-details', component: CustomerDetailsComponent},
    {path: 'order-summary', component: OrderSummaryComponent}, // wont this require some path parameters? {orderId}
    {path: 'payment-failed', component: PaymentFailedComponent},
    {path: 'payment-success', component: PaymentSuccessComponent},
    {path: 'payment-success/:id', component: PaymentSuccessComponent}, // this should really be the invoice route
    {path: 'product-detail/:id', component: BizProductDetailComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }