<section id="customer-details" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>Customer</span> Details</h2>
            <p>Please enter some details about yourself so we can have your order processed promptly.</p>
        </div>

        <div class="row">
            <!-- (@sach) TODO: add required tags and div for errors -->
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #f="ngForm" (ngSubmit)="submit(f)">
                        <!-- <div *ngIf='!isProdEnv' class="alert alert-danger">Development Environment</div> -->
                        <div class="row">
                            <div *ngIf="express" ngclass="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <!-- tooltip to take in the company names and reformat them into pills -->
                                    <label for="CompanyName">Preferred Company Names</label>
                                    <input ngModel name="companyName"type="text" class="form-control"
                                    >
                                </div>
                            </div>

                            <div class="col-lg-8 col-md-12">
                                <div class="form-group">
                                    <div *ngIf='fullName.touched && !fullName.valid' class="alert alert-danger">Please check your spelling</div>
                                    <label>Full Name</label>
                                    <input
                                    pattern="^[a-zA-Z’ ]+(?:\[a-zA-Z0-9-]+)*$"
                                    ngModel
                                    name="fullName"
                                    type="text"
                                    class="form-control" 
                                    #fullName='ngModel'
                                    [value] = "devFormValue.fullName"
                                    >
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Identity Type</label>
                                    <select
                                        required
                                        [(ngModel)]="selectedIdType"
                                        class="form-control"
                                        name="idType"
                                        #idType='ngModel'>
                                        <option
                                            *ngFor="let option of options | keyvalue"
                                            [value]=option.key>
                                                {{ option.value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6" [ngSwitch]="selectedIdType">
                                <ng-template [ngSwitchCase]="'passport'">
                                        <div class="form-group">
                                            <label>Passport Number</label>
                                            <div *ngIf='idNumber.touched && !idNumber.valid' class="alert alert-danger">Please enter a valid Passport Number</div>
                                            <input
                                            required
                                            [pattern]="options[selectedIdType]['regexPattern']"
                                            maxlength="13"
                                            [value] = "devFormValue.idNumber"
                                            ngModel name="idNumber"type="text" placeholder="Passport Number" class="form-control" #idNumber='ngModel'>
                                        </div>
                                </ng-template>
                                <ng-template [ngSwitchCase]="'sa-id'">
                                        <div class="form-group">
                                            <label>Identity Number</label>
                                            <div *ngIf='idNumber.touched && !idNumber.valid' class="alert alert-danger">Please enter a valid South African identity mumber</div>
                                            <input
                                            required
                                            [pattern]="options[selectedIdType]['regexPattern']"
                                            maxlength="13"
                                            [value] = "devFormValue.idNumber"
                                            ngModel name="idNumber"type="text" placeholder="South African National Registration Number" class="form-control" #idNumber='ngModel'>
                                        </div>
                                </ng-template>
                                <ng-template ngSwitchDefault>
                                        <div class="form-group">
                                            <label>Identity Number</label>
                                            <div *ngIf='idNumber.touched && !idNumber.valid' class="alert alert-danger">Please enter a valid South African identity mumber</div>
                                            <input
                                            required
                                            [pattern]="pattern"
                                            maxlength="13"
                                            [value] = "devFormValue.idNumber"
                                            ngModel name="idNumber"type="text" placeholder="South African National Registration Number" class="form-control" #idNumber='ngModel'>
                                        </div>
                                </ng-template>
                            </div>

                            <!-- (@sach) TODO: if the entered number was not a national Registration number then make available the ngif bounded block to upload a copy of the passport -->

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <!-- (@sach) TODO: https://sachcoza.atlassian.net/browse/OR-115 -->
                                    <div *ngIf='email.touched && !email.valid' class="alert alert-danger">Please enter a valid email</div>
                                    <input 
                                    required
                                    pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                    [value] = "devFormValue.email"
                                    ngModel name="email" type="text" class="form-control" #email='ngModel'>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <div *ngIf='phone.touched && !phone.valid' class="alert alert-danger">Please enter a valid phone number</div>
                                    <!-- (@sach) TODO: https://sachcoza.atlassian.net/browse/OR-115 -->
                                    <input
                                    required
                                    minlength="10"
                                    pattern="^[0-9+ ]*$"
                                    [value] = "devFormValue.phone"
                                    ngModel name="phone" type="text" class="form-control" #phone='ngModel'>
                                </div>
                            </div>

                            <div class=" col-lg-12 col-md-6">
                                <div class="form-group" style="
                                    font-size: 12px;
                                    text-align: justify;
                                    ">
                                     <p>
                                         <input
                                         ngModel 
                                         required
                                         name="powerOfAttorney"
                                         type="checkbox">
                                         I <em>(The Principal)</em> appoint Online Register <em>(The Agent)</em> and his/her representative to manage and transact in my legal and business affairs in the Republic of South Africa. The Principal gives the Agent full power and authority to act on his/her behalf, in his/her name, for his/her account and benefit. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <!-- <button type="submit" class="btn btn-primary" [disabled]="!f.valid && isProdEnv">Confirm Details</button> -->
                            <button type="submit" class="btn btn-primary" [disabled]="!f.valid">Confirm Details</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</section>