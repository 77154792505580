import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Enquiry } from 'src/app/models/enquiry/enquiry';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  private enquiry;
  private collectionName : string = '/enquries/'

  constructor(private db : AngularFireDatabase) { }

  // this method updates the database object with a product id
  // (HACKS: update vs set, getData())
  private updateID(enquiry : Enquiry) : boolean{
    this.db.object(this.collectionName+enquiry.getId())
    // (OR-41.1, @sach) TODO: change this to an update method rather than a set (destructive)
    // this was hack to avoid the '0' that was appearing in the 
    // database structure
    .set(
      // (OR-41.2, @sach) TODO: this getData() call needs to be reworked so it
      //  doesnt need to happen like this
      enquiry.getData()
    ).finally(
      // (@sach) TODO: this function must have a return value
    );

    return true // (@sach) TODO: this is in the wrong place
  }
  
  // this method adds a product to the firebase database
  // and returns the key for that product. this method
  // is done is a very HACKY way, try and tidy up
  // HACK (back and forth between db and service)
  // this method is made private so that additn to the database is done securely?
  private addEnquiry(enquiry : Enquiry) : string {

    let id = this.db.list(this.collectionName).push(enquiry).key;
    enquiry.setId(id);
    this.updateID(enquiry);
    return id;
  }

  setEnquiryDetails(enquiry : Enquiry){
    // set the local user record, first you must ask if this is a different user? before overwriting
    // exception checking : ensure that user is not null before setting this.user to user
    this.enquiry = enquiry;
    // set the database user record (a user account is created automatically)
    // (@sach) TODO: we need to then send these over to the server
    this.addEnquiry(this.enquiry);
  }


}
