<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <p>Even with the best of product our clients come first. Our passion is to always be concerned about what the objective of the client and what they are looking to achieve through us. </p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                    <h3>Company Registrations</h3>
                    <p style="text-align: center">Company registration in record time. You have the option to choose a package dependant on your needs.Qualified Accountants. Expert Service and Advice.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fab fa-linode"></i>
                    </div>
                    <h3>Shelf Company Sales</h3>
                    <p style="text-align: center">Choose from a selection of shelf companies in our shelf company marketplace today. These are pre-registered companies ready for you to start trading immediatley.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-desktop"></i>
                    </div>
                    <h3>Accounting</h3>
                    <p style="text-align: center">Streamlined and effective accounting processes with high level of automation Keep control of your accounting and tax operations in South Africa.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>Business Consulting</h3>
                    <p style="text-align: center">We offer a wide range of business consulting services, providing expertise in a specific market. We are able to identifying problems and initialing the change to resolve them.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-anchor"></i>
                    </div>
                    <h3>Tax Advisory</h3>
                    <p style="text-align: center">Full-range tax advisory for your CIT, VAT, PAYE: registrations, return filing and more. Tackle the complexity of your local and cross-border tax compliance.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-headphones"></i>
                    </div>
                    <h3>Compliance Consulting</h3>
                    <p style="text-align: center">Compliance Consulting renders compliance risk management and advisory services to all parties requiring assistance.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>