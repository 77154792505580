import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Order } from 'src/app/models/order/order.model';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  order : Order;
  private collectionName : string = '/orders/'

  constructor(private db : AngularFireDatabase) { }
  // this method updates the database object with a product id
  // (HACKS: update vs set, getData())
  private async updateID(order : Order) {
    
    // get the invoice number and add it to the order
    let invoiceNo = await this.db.database.ref('/variables/invoices/count')
    .once('value')
    .then(res => {

      // get invoice no
      let _invoiceNo = res.val()
      // update invoice no
      _invoiceNo++;
      // write back new invoice no to db
      this.db.object('/variables/invoices/count')
    .set(_invoiceNo)
    .finally();
    //update order with new invoice no
    order.setInvoiceNo(_invoiceNo);
      
    //write the updated order object back to the db
    this.db.object(this.collectionName + order.getId())
    // (OR-41.1, @sach) TODO: change this to an update method rather than a set (destructive)
    // this was hack to avoid the '0' that was appearing in the 
    // database structure
    .set(
      // (OR-41.2, @sach) TODO: this getData() call needs to be reworked so it
      //  doesnt need to happen like this
      order.getData()
    ).finally(
      // (@sach) TODO: this function must have a return value
    );

    });    
    return true // (@sach) TODO: this is in the wrong place
  }
  
  getOrder(orderId : string){
    return this.db.object(this.collectionName+orderId);
  }
  // this method adds a product to the firebase database
  // and returns the key for that product. this method
  // is done is a very HACKY way, try and tidy up
  // HACK (back and forth between db and service)
  // this method is made private so that additn to the database is done securely?
  private addOrder(order : Order) : string {
    let id = this.db.list(this.collectionName).push([order]).key;
    order.setId(id);
    this.updateID(order);
    return id;
  }

  setOrderDetails(order : Order){
    // set the local user record, first you must ask if this is a different user? before overwriting
    // exception checking : ensure that user is not null before setting this.user to user
    this.order = order;
    // set the database user record (a user account is created automatically)
    // (@sach) TODO: we need to then send these over to the server
    this.addOrder(this.order);
  }
}
