import { User } from '../user/user.model';
import { Product } from '../product/product';

export class Order {

    id : string;
    products : Product[];
    orderDate : string;
    status : string;
    user : User;
    invoiceNo : number

    constructor (
        user : User,
        products : Product[]
    ) {
        this.id = null;
        this.invoiceNo = null;
        this.status = "PENDING";
        this.orderDate = Date.now().toString();
        this.user = user;
        this.products = products;
    }

    setId(id : string){
        this.id = id;
    }

    setUser(user : User){
        this.user = user;
    }

    setStatus(status : string){
        this.status = status;
    }

    // might need to remove this method
    setOrderDate(orderDate : string){
        this.orderDate = orderDate
    }

    setProducts(products : Product[]){
        this.products = products;
    }

    setInvoiceNo(invoiceNo : number){
        this.invoiceNo = invoiceNo;
    }

    getId(){
        return this.id;
    }

    getUser(){
        return this.user;
    }

    getStatus(){
        return this.status;
    }

    getOrderDate(){
        return this.orderDate;
    }

    // TODO: https://sachcoza.atlassian.net/browse/OR-145
    // addProduct(){}

    // TODO: https://sachcoza.atlassian.net/browse/OR-146
    // removeProduct(){}

    getProducts(){
        return this.products;
    }

    getInvoiceNo(){
        return this.invoiceNo;
    }

    getData(){
        return {
            id : this.id,
            user : this.user,
            status : this.status,
            products : this.products,
            orderDate : this.orderDate,
            invoiceNo : this.invoiceNo,
        }
    }




}