
export class Enquiry{

    private id: string;
    private name : string;
    private email: string;
    private phone: string; // to cater for +27 with international codes
    private title: string; // message title
    private message : string; // message body
    private timestamp : string;
    private status : string;

    constructor(
        name : string,
        email : string,
        phone : string,
        title : string,
        message : string,
        id? : string,
        ){
        // (OR-17,@sach) TODO: implement this
        this.id = null;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.title = title;
        this.message = message;
        this.timestamp = Date.now().toString();
        this.status = "OPEN"; // this needs to be an enum
        // this.id = id; // this will need an "undefined check"
        // this.description = description; // this will need an "undefined check"
    }

    // (OR-32, @sach) TODO: might need to remove this because database should assign this
    setId(id : string){
        this.id = id;
    }

    setName(name : string) : void{
        this.name = name;
    }

    setEmail(email: string){
        this.email = email;
    }

    setPhone(phone : string){
        this.phone = phone;
    }

    setTitle(title : string){
        this.title = title;
    }

    setMessage(message : string){
        this.message = message;
    }

    setTimestamp(timestamp: string){
        this.timestamp = timestamp;
    }

    setStatus(status : string){
        this.status = status;
    }

    getId() : string{
        return this.id;
    }

    getName() : string{
        return this.name;
    }

    getPhone(){
        return this.phone;
    }

    getTitle(){
        return this.title;
    }

    getMessage(){
        return this.message;
    }

    getTimestamp(){
        return this.timestamp;
    }
    
    getStatus(){
        return this.status;
    }


    // (@sach) TODO: had to include a get data method to get all the data
    getData(){
        return {
            id : this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            title: this.title,
            message: this.message,
            timestamp: this.timestamp,
            status: this.status
        }
    }
}