import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { UserDetailsService } from 'src/app/services/user-details/user-details.service';
import { User } from 'src/app/models/user/user.model';
import { OrderService } from 'src/app/services/order/order.service';
import { Order } from 'src/app/models/order/order.model';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  shoppingCart;
  user : User;
  order : Order;
  shoppingCartTotal = 0;

  constructor(
    private service : ShoppingCartService,
    private service2 : UserDetailsService,
    private service3 : OrderService) {
    this.user = service2.getUser();
    this.shoppingCart = service.getCartItems();
    this.shoppingCartTotal = service.getCartTotal();
    this.order = service3.order;
   }

  ngOnInit(): void {
  }

}
