<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="section-title" style="margin-bottom: 50px;">
            <h2>Business <span>Marketplace</span></h2>
            <!-- or Post an Ad and Sell a Business Online!  -->
            <!-- or sell a business -->
            <!-- , and business brokers connects you to a wealth of business opportunities and resources. -->
            <!-- place a Business for Sale Listing or  -->
            <!-- or Sell  -->
            <p>
                Search Businesses for Sale and Listings. We are a leading marketplace designed to help you find your perfect business. Our database of businesses for sale and franchise opportunities connects you to a wealth of business opportunities and resources. We look forward to helping you find a great business to purchase. Buy a Business today!
            </p>
        </div>

        <div class="col">
            <div *ngFor="let product of products" style="padding:20px; margin-bottom: 20px; border-radius: 10px;" class="listing-card">
                <div (click)="routeToProductDetail(product.id)" class="row">
                    <div class="article-img col-lg-3 col-md-3">
                        <img style="border-radius: 10px;" [src]="product.imageUrl" alt="blog-details">
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <h3 style="margin-bottom: 5px;"><strong>{{ product.name }}</strong></h3>
                        <h6 style="color: hotpink; margin-bottom: 10px;">
                            {{ product.subTitle }}
                        </h6>
                        <p style="margin-bottom: 10px;"> {{ product.description | truncate:[200, '...'] }}
                            <a href="#"><span style="color: hotpink;">read more...</span></a>
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <h3 
                    style="
                    color: hotpink;
                    "><strong>{{ product.price | currency:'R ':'symbol':'1.0' }}</strong></h3>
                    <p>{{ product.location }}</p>
                    <a
                        style="color: white; position: absolute; right: 0; bottom: 0; margin: 20px"
                        class="btn btn-primary">Contact Seller</a>
                    </div>
                </div>
            </div>
          </div>
        
    </div>
</section>