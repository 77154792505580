import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { Order } from 'src/app/models/order/order.model';
import { jsPDF } from "jspdf";
import { autoTable } from 'jspdf-autotable';
import 'jspdf-autotable';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  order : Order;
  orderId;
  variableName$;

  constructor(private service : OrderService,
    private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.orderId = paramMap['params']['id'];
    })  
  }

  async downloadInvoice(){
    this.service.getOrder(this.orderId)
    .valueChanges()
    .subscribe(response => {
      const doc = new jsPDF();
      //settings
      doc.setFontSize(9);

      const logoSizeRatio = 3;
      const logoWidth = 10*logoSizeRatio;
      const logoHeight = 8*logoSizeRatio;
      const addressLine1 = "";
      const addressLine2 = "";
      const pageWidth = 287;

      // get order date
      const unixTimestamp = response['orderDate'];
      const milliseconds = parseInt(unixTimestamp);
      const dateObject = new Date(milliseconds);
      const humanDateFormat = dateObject.toLocaleString().split(",")[0];

      doc.text("ACCOUNTANTS & TAX PRACTITIONERS", 104, 20,{align : "center"});
      doc.addImage("../../../../../assets/img/logo.png", "PNG", 90, 25, logoWidth, logoHeight);
      doc.text("Online Register (Pty) Ltd", 105, 10,{align : "center"});
      
      doc.text("117 Minauch Road", 10, 25);
      doc.text("Harmelia", 10, 30);
      // doc.text("1429", 10, 20);
      doc.text("1429, South Africa", 10, 35);
      // split up the label and the value
      // perhaps store them as a dict
      doc.text("VAT No: 4446660257", 10, 40);

      doc.text("Reg 2013/053502/07", 200, 25,{align : "right"});
      doc.text("www.onlineregister.co.za", 200, 30,{align : "right"});
      // add the readibility spaces to telephone numbers
      doc.text("Fax: 0865561190", 200, 35,{align : "right"});
      // split these numbers up
      doc.text("Telephone: +27113015599/0116146990", 200, 40,{align : "right"});

      // add horizontal line here
      doc.line(10,45,88,45);
      doc.line(122,45,200,45);

      doc.text("TAX INVOICE", 10, 55);
      let invoiceDate = "Date: " + humanDateFormat;
      doc.text(invoiceDate, 10, 60);
      doc.text("The Director", 10, 70);
      let clientName = response['user'].name;
      doc.text(clientName, 10, 75);
      // doc.text("Address", 10, 80);
      let phone = "Cellphone: " + response['user'].phone;
      doc.text(phone, 10, 80);
      let email = "Email: " + response['user'].email;
      doc.text(email, 10, 85);

      let invoiceNo = "Tax Invoice No: " + response['invoiceNo']
      doc.text(invoiceNo, 195, 85,{align : "right"});

      let columns = [
        {header: "Date", dataKey: "date"},
        {header: "Product Description", dataKey: "description"},
        {header: "Amount", dataKey: "amount"},
        {header: "Payable", dataKey: "payable"},
      ];
      let rows = [];

      let i = 0;
      let orderTotal = 0;
      for(i = 0; i < response['products'].length; i++ ){
        rows.push({
            "date": `${humanDateFormat}`,
            "description": `${response['products'][i].name}`,
            "amount": `R ${response['products'][i].price}`,
            "payable" : `R ${response['products'][i].price}`
        });
        orderTotal += response['products'][i].price;
      }
      //sub total
      let subTotal = orderTotal*0.85
      rows.push({
        "date": '',
        "description": 'Sub Total',
        "amount": '',
        "payable" : "R " + subTotal
      })
      // vat
      let vat = orderTotal*0.15
      rows.push({
        "date": '',
        "description": 'VAT @ 15%',
        "amount": '',
        "payable" : "R " + vat
      })
      // total
      rows.push({
        "date": '',
        "description": 'Total',
        "amount": '',
        "payable" : "R " + orderTotal
      })

      // bank details
      doc.text("Online Register Account Details", 10, 230);
      doc.text("Name: ONLINE REGISTER (PTY) LTD", 10, 235);
      doc.text("Bank: FNB", 10, 240);
      doc.text("Acc No: 62436314207", 10, 245);
      doc.text("Branch: 253001", 10, 250);
      doc.text("Acc Type: Cheque Account", 10, 255);

      doc.text("Thank You For Your Business", 100, 280,{align : "center"});
      
      // footer
      // horizontal line
      doc.line(10,285,200,285);
      doc.text("Directors: T.Makayi (Mr.)(Managing); Regina Masanga;", 10, 290);

      // generate table
      doc['autoTable']({
        body: rows,
        columns: columns,
        startY: 110,
      })

      doc.save("invoice.pdf");
    });
  
  }

}
