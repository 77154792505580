export class Product{

    private id: string;
    private name : string;
    private price: number;
    private description : string;
    private processingTime : string; // (OR-27, @sach) TODO: change this to a better data type that handles time
    private subProducts : any[]; // (OR-34, @sach) TODO: this should be improved and made into a Product array
    private category : string; // (OR-28, @sach) TODO: change this data type to a ENUM for product categories
    private backgroundImgUrl: string;

    constructor(
        name : string,
        price : number,
        subProducts : any[],
        processingTime : string,
        description : string,
        category : string, // (OR-28, @sach) TODO: change this data type to a ENUM for product categories
        backgroundImgUrl : string, // (OR-101, @sach) TODO: make this an optional parameter
        id? : string,
        ){
        // (OR-17,@sach) TODO: implement this
        this.id = null;
        this.name = name;
        this.price = price;
        this.subProducts = subProducts;
        this.processingTime = processingTime;
        this.description = description; // this will need an "undefined check"
        this.category = category;
        this.backgroundImgUrl = backgroundImgUrl; // this will also need an "undefined check" when made into an online parameter
        this.id = id; // this will need an "undefined check"
    }

    setBackgroundImgUrl(url : string){
        this.backgroundImgUrl = url;
    }

    // (OR-32, @sach) TODO: might need to remove this because database should assign this
    setId(id : string){
        this.id = id;
    }

    setCategory(category : string){
        this.category = category;
    }

    setProcessingTime(time : string){
        this.processingTime = time;
    }

    setName(name : string) : void{
        this.name = name;
    }

    setPrice(price : number) : void{
        this.price = price;
    }

    setDescription(desc : string) : void{
        this.description = desc;
    }

    getId() : string{
        return this.id;
    }

    getName() : string{
        return this.name;
    }

    getPrice() : number{
        return this.price
    }

    getDescription() : string{
        return this.description;
    }

    // getSubProducts() : Product[]{
    //     return this.subProducts;
    // }

    getSubProducts() : any[] {
        return this.subProducts;
    }

    getProcessingTime(){
        return this.processingTime;
    }

    getCategory(){
        return this.category;
    }

    getBackgroundImgUrl() : string {
        return this.backgroundImgUrl;
    }

    // (@sach) TODO: had to include a get data method to get all the data
    getData(){
        return {
            id : this.id,
            name: this.name,
            price : this.price,
            // description: this.description, // (@sach) TODO: description is not yet used
            // subProducts: this.subProducts, // (@sach) TODO: subProducts are not yet used
            processingTIme: this.processingTime,
            category : this.category

        }
    }

    // addSubProduct(product : Product) : void{
    //     this.subProducts.push(product);
    // }

    // removeSubProduct(id : number) : void{
    //     // (OR-16,@sach) TODO: implement this
    // }
}