<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked <span>Questions</span></h2>
            <p>Below you will find answers to the most frequently asked questions regarding company registrations within South Africa.</p>
        </div>
       
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <h5 class="mb-0">
                                    Can a foreigner register a company in South Africa? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">
                                Yes, so long as the person registering the new company has a valid passport.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h5 class="mb-0">
                                    How are companies incorporated under the Companies Act? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">
                                A new company is incorporated by lodging the notice of incorporation, and adopting either the CIPC’s standard short form or the long form of the memorandum of incorporation (“MoI”) (which replaces the memorandum and articles of association). The MoI is the only constitutional document of the company that sets out the rights, duties and responsibilities of the shareholders, directors and others within, and in relation to, the company, and is binding between the company and shareholders and between the company and each director, prescribed officer and member of the board committee.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h5 class="mb-0">
                                    What is an external company? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">
                                It is a foreign company that is carrying on business in South Africa. All foreign companies must register in South Africa within 20 days of conducting business within South Africa.
                            </div>
                        </div>
                    </div>

                    <div class="card mb-0">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <h5 class="mb-0">
                                    Who can be the director of a company? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">
                                Any natural person with a valid identity document or passport can be a director of a company.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>