<section class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Who We <span>Are</span></h2>
            <p>Online Register was founded on the principle of sound business efficacy providing outsourced solutions in a busy and dynamic world.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>We are fast</h3>
                    <p>With business challenges of timely document submissions to various stakeholders. Partnership with us provides the needed extra peace of mind.</p>
                    <span>1</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>We are professional</h3>
                    <p>We have realistic timelines which we adhere to, ensuring that you meet deadlines at SARS, or for that crucial tender, contract or business deal.</p>
                    <span>2</span>
                    <div class="card-border"></div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>We are passionate</h3>
                    <p>We seek our customer's satisfaction. Our services are unparalleled in depth. We handle every assignment with love.</p>
                    <span>3</span>
                </div>
            </div>
        </div>
    </div>
</section>