import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details/user-details.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user/user.model';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  user :User;
  shoppingCart;
  express;
  isProdEnv = environment.production;
  devFormValue;
  selectedIdType ='id';
  options = {
    passport : {
      name : "Passport Number",
      regexPattern: "^(?!.*\/\/)[A-Za-z][A-Za-z0-9\/]*$"
    },
    id : {
      name : "South African Identity Number",
      regexPattern: "^[0-9]{13}$"
    }
  }
  pattern = "^[0-9]{13}$";

  constructor(
    
    private service2 : UserDetailsService,
    private service : ShoppingCartService,
    private router : Router) {
      this.shoppingCart = service.getCartItems();
      this.express = this.shoppingCart[0].getName() == "Express" ? true : false;

    // preloading the form in dev mode
    if(!this.isProdEnv){
      this.devFormValue = {
        fullName : "Development Name",
        idNumber : "2001014800086",
        email : "dev@onlineregister.co.za",
        phone : "+27123456789",
      }
    }

   }

  ngOnInit(): void {
  }

  submit(f){
    this.user = new User(f.value.fullName, f.value.idType, f.value.idNumber, f.value.email, f.value.phone)
    this.service2.setUserDetails(this.user);
    this.router.navigate(['/order-summary']);
  }

}
