<footer class="footer-area">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div>
                <img style="height: 35px;" src="../../../../favicon.ico">
            </div>
            <div style="padding-top: 25px;">
                <h3><a routerLink="/"><span> Online </span>Register</a></h3>
            </div>
        </div>
        <p>Copyright <i class="far fa-copyright"></i> 2020 All Rights Reserved.</p>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i></div>