import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { OrderService } from 'src/app/services/order/order.service';
import { User } from 'src/app/models/user/user.model';
import { UserDetailsService } from 'src/app/services/user-details/user-details.service';
import { Order } from 'src/app/models/order/order.model';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  shoppingCart;
  shoppingCartTotal = 0;
  user : User;
  // this should be done on the html side
  hasProducts = false;

  constructor(
    private service : ShoppingCartService,
    private service2 : OrderService,
    private service3 : UserDetailsService) {
    this.shoppingCart = service.getCartItems();
    if (this.shoppingCart.length > 0){
      this.hasProducts = true;
    }
    this.shoppingCartTotal = service.getCartTotal();
    this.user = service3.getUser();
   }

  ngOnInit(): void {
  }

  placeOrder(){
    this.service2.setOrderDetails(new Order(this.user, this.shoppingCart));
  }

  delete($event){
    let selectedProductId : string  = $event.srcElement.id.split('DelBtn')[0];
    let selectedProduct = this.shoppingCart
    .find(
      product => product.getId() == selectedProductId);
    this.service.removeProduct(selectedProduct);
    // ugh updating everything
    this.shoppingCart = this.service.getCartItems();
    this.shoppingCartTotal = this.service.getCartTotal();
    console.log(this.shoppingCart);
  }
}
