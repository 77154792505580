import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { User } from 'src/app/models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  private collectionName : string = '/users/'
  user : User;

  constructor(private db : AngularFireDatabase) { }


  private updateID(user : User) : boolean{
    this.db.object(this.collectionName +user.getId())
    // (OR-41.1, @sach) TODO: change this to an update method rather than a set (destructive)
    // this was hack to avoid the '0' that was appearing in the 
    // database structure
    .set(
      // (OR-41.2, @sach) TODO: this getData() call needs to be reworked so it
      //  doesnt need to happen like this
      user.getData()
    ).finally(
      // (@sach) TODO: this function must have a return value
    );

    return true // (@sach) TODO: this is in the wrong place
  }

  // TODO: https://sachcoza.atlassian.net/browse/OR-133
  private addUser(user : User) : string {
    let id = this.db.list(this.collectionName).push([user]).key;
    user.setId(id);
    this.updateID(user);
    return id;
  }

  // get the user details
  getUser() : User{
    // (@sach) TODO: we need to call these from the server (if the local user object is not set i.e null)
    return this.user
  }

   setUserDetails(user : User){
    // set the local user record, first you must ask if this is a different user? before overwriting
    // exception checking : ensure that user is not null before setting this.user to user
    this.user = user;
    // set the database user record (a user account is created automatically)
    // (@sach) TODO: we need to then send these over to the server
    this.addUser(this.user);
  }

}
