<section id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" style="margin-bottom: 20px;">
            <h2>Shelf Company <span>Marketplace</span></h2>
            <p>We have a selection of shelf companies for sale with various incorporation dates to suit your needs. Whether you need a company which is 6 months old or 3 years old, we will have a shelf company for you.</p>
        </div>
        
        <div class="shorting-menu">
            <!-- (@sach) TODO: https://sachcoza.atlassian.net/browse/OR-112 -->
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".Individual-Services">Individual Services</button>
            <button class="filter" data-filter=".2020">2020</button>
            <button class="filter" data-filter=".2019">2019</button>
            <button class="filter" data-filter=".2018">2018</button>
            <button class="filter" data-filter=".2017">2017</button>
            <button class="filter" data-filter=".2016">2016</button>
            <button class="filter" data-filter=".2015">2015</button>
            <button class="filter" data-filter=".uif-reg">UIF</button>
            <button class="filter" data-filter=".vat">VAT</button>
            <button class="filter" data-filter=".paye">PAYE</button>
            <button class="filter" data-filter=".bank-account">Bank Account</button>
            <button class="filter" data-filter=".coid">COID</button>
            <button class="filter" data-filter=".cidb">CIDB</button>
            <button class="filter" data-filter=".import-export-permit">Import Export Permit</button>
            <!-- TODO: add share certificate to tags and filters here too -->
        </div>
    </div>

    <div class="container-fluid">
        <div class="shorting">
            <div class="row">
                <div style="border-radius: 10px;" *ngFor="let product of products" [class]="'col-lg-3 col-md-6 mix' + ' ' + product.getCategory()">
                    <div *ngIf='product.getCategory().includes("Individual-Services")' class="single-work">
                        <img style="border-radius: 10px;" [src]=product.getBackgroundImgUrl() [alt]="product.getName() + ' ' + 'Background Image'">
                        
                        <div style="border-radius: 10px;" class="work-content">
                            <!-- TODO: maybe move this so that the entire tile is clickable -->
                            <a [id]="product.getId() + 'Btn'" class="popup-btn">
                                <div>
                                    <h3>
                                        <strong>
                                            {{ product.getName() }}
                                        </strong>
                                    </h3>
                                    
                                    <h3 
                                    style="
                                    color: hotpink;
                                    ">{{ product.getPrice() | currency:'R ':'symbol':'1.0' }}</h3>
                                    <!-- TODO: this should highlight and allow a tooltip kind of explanation what each sub product is and stuff -->
                                    <ul *ngFor="let subProduct of product.getSubProducts()">
                                        <li>{{ subProduct }}</li>
                                    </ul>
                                </div>
                            </a>
                        </div>

                        <div 
                        [id]="product.getId()+'Popup'" 
                        class='white-popup mfp-hide'>
                                <div  class='test-work'
                                style="padding: 20px;">
                                    <div class="row">
                                        <div class="col-lg-7 col-md-12">
                                            <img class="img-popup"
                                            [src]=product.getBackgroundImgUrl() [alt]="product.getName() + ' ' + 'Background Image'">
                                        </div>
                                        <div class="col-lg-5 col-md-12"
                                        style="padding-bottom: 10px;">
                                            <h3 
                                            style="
                                            padding-bottom: 10px;">
                                                <strong>{{ product.getName() }}</strong>
                                            </h3>
                                            <h3 style="
                                            color: hotpink;
                                            padding-bottom: 10px;
                                            ">
                                            {{ product.getPrice() | currency:'R ':'symbol':'1.0'  }}</h3>
                                            <p style="
                                            text-align: justify;
                                            padding-bottom: 10px;
                                            ">
                                            {{ product.getDescription() }} 
                                            </p>
                                            <ul *ngFor="let subProduct of product.getSubProducts()">
                                                <li>
                                                    <!--  -->
                                                    <i onclick='' class="fas fa-check"
                                                    style="
                                                    color: hotpink;
                                                    cursor: pointer;
                                                    "
                                                    ></i>
                                                    {{ subProduct }}
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                            <div 
                                            style="
                                            text-align: right;
                                            padding: 20px;
                                            ">
                                            <a [id]="product.getId() + 'BuyBtn'" class="btn btn-primary" routerLink='/customer-details' (click)="selectProduct($event)">Purchase Product</a>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shorting">
            <div class="row">
                <div style="border-radius: 10px;" *ngFor="let product of products" [class]="'col-lg-3 col-md-6 mix' + ' ' + product.getCategory()">
                    <div *ngIf='!product.getCategory().includes("Individual-Services")' class="single-work">
                        <img style="border-radius: 10px;" [src]=product.getBackgroundImgUrl() [alt]="product.getName() + ' ' + 'Background Image'">
                        
                        <div style="border-radius: 10px;" class="work-content">
                            <!-- TODO: maybe move this so that the entire tile is clickable -->
                            <a [id]="product.getId() + 'Btn'" class="popup-btn">
                                <div>
                                    <h3>
                                        <strong>
                                            {{ product.getName() }}
                                        </strong>
                                    </h3>
                                    
                                    <h3 
                                    style="
                                    color: hotpink;
                                    ">{{ product.getPrice() | currency:'R ':'symbol':'1.0'  }}</h3>
                                    <!-- TODO: this should highlight and allow a tooltip kind of explanation what each sub product is and stuff -->
                                    <ul *ngFor="let subProduct of product.getSubProducts()">
                                        <li>{{ subProduct }}</li>
                                    </ul>
                                </div>
                            </a>
                        </div>

                        <div 
                        [id]="product.getId()+'Popup'" 
                        class='white-popup mfp-hide'>
                                <div  class='test-work'
                                style="padding: 20px;">
                                    <div class="row">
                                        <div class="col-lg-7 col-md-12">
                                            <img class="img-popup"
                                            [src]=product.getBackgroundImgUrl() [alt]="product.getName() + ' ' + 'Background Image'">
                                        </div>
                                        <div class="col-lg-5 col-md-12"
                                        style="padding-bottom: 10px;">
                                            <h3 
                                            style="
                                            padding-bottom: 10px;">
                                                <strong>{{ product.getName() }}</strong>
                                            </h3>
                                            <h3 style="
                                            color: hotpink;
                                            padding-bottom: 10px;
                                            ">
                                            {{ product.getPrice() | currency:'R ':'symbol':'1.0'  }}</h3>
                                            <p style="
                                            text-align: justify;
                                            padding-bottom: 10px;
                                            ">
                                            {{ product.getDescription() }} 
                                            </p>
                                            <ul *ngFor="let subProduct of product.getSubProducts()">
                                                <li>
                                                    <!--  -->
                                                    <i onclick='' class="fas fa-check"
                                                    style="
                                                    color: hotpink;
                                                    cursor: pointer;
                                                    "
                                                    ></i>
                                                    {{ subProduct }}
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                            <div 
                                            style="
                                            text-align: right;
                                            padding: 20px;
                                            ">
                                            <a [id]="product.getId() + 'BuyBtn'" class="btn btn-primary" routerLink='/customer-details' (click)="selectProduct($event)">Purchase Product</a>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>