import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry/enquiry';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-biz-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class BizProductDetailComponent implements OnInit {

  product;
  enquiry;

  constructor(private service : ProductService,private route : ActivatedRoute, private service2 : EnquiryService) {
    this.route.paramMap.subscribe(paramMap => {
      this.product = this.service.getProduct(paramMap['params']['id']);
    })
   }

  ngOnInit(): void {
  }

  submit(f){
    let title = "New Business Sale Enquiry" + " : " + this.product.name;
    this.enquiry = new Enquiry(
      f.value.name,
      f.value.email,
      f.value.phone,
      title,
      f.value.message);
    this.service2.setEnquiryDetails(this.enquiry);
    // // clear the form
    f.reset();
}

}
