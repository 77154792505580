<section id="pricing" 
class="pricing-area pt-100 pb-70 bg-F7F5F4"
style="padding-top: 50px;">
    <div class="container">
        <div class="section-title">
            <h2>Product <span>Pricing</span></h2>
            <p style="margin-bottom: 20px;">We offer unmatched affordable business registration services. Whether you are a solo-preneur, startup or
                well-established business, we have a solution for you.</p>
        </div>

        <!-- (@sach) TODO: change the naming here from why-we-are-different to something more relevant -->
        <div class="tabset why-we-different-tabset">
            <!-- (@sach) TODO: center these tabs -->
            <!-- Tab 1 -->
            <input hidden type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label hidden for="tab1">Basic Registrations</label>
            <!-- Tab 2 -->
            <input hidden type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <!-- (@sach) TODO: hate the naming of this tab. change to something more appropirate -->
            <label hidden for="tab2">Packages</label>

            <div class="tab-panels">
                <section id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">

                        <div class="col-lg-12 col-md-12">
                            <div class="why-we-different-content">
                                <!-- (@sach TODO: added some inline styling here that needs to be transfered to the css file -->
                                <div class="row">
                                    <!-- (@sach) TODO: include a crossed out value for a *special -->
                                    <!-- (@sach) TODO: highlight each packages additional value elements -->
                                    <!-- (@sach) TODO: include tooltips to show who each package is designed for -->
                                    <!-- (@sach) TODO: make all the pricing tables the same height -->
                                    <!-- (@sach) TODO: make the standard/most popular package have a stroke already to encourage user to select -->
                                    <!-- (@sach) TODO: indicate that express is the most popular package -->
                                    <div class="col-lg-6 col-md-6 col-sm-12 offset-lg-0" *ngFor="let product of basicRegistrationCombos">
                                        <div class="pricingTable">
                                            <h3 class="product-id" style="display: none;">{{ product.id }}</h3> <!-- (OR-33, @sach) TODO: figure out a better way of having the id of the product the user wants -->
                                            <h3 class="title">{{ product.getName() }}</h3>
                                            <div class="price-value">
                                                <span class="amount">{{ product.getPrice() | currency:'R ':'symbol':'1.0' }}</span>
                                            </div>
                                            
                                            <ul class="pricing-content">
                                                <li><i class="fas fa-check"></i>{{ "Company Registration" }}</li> <!-- (@sach) TODO: this is just hanging? -->
                                                <li><i class="fas fa-check"></i>{{ product.processingTime }}</li>
                                                <li *ngFor="let subProduct of product.subProducts"><i class="fas fa-check" ></i>{{ subProduct }}</li>
                                                
                                            </ul>
                                            <a [id]="product.id" class="btn btn-primary" routerLink='/customer-details' (click)="selectProduct($event)">Get Started</a>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12 offset-lg-0" *ngFor="let product of packageRegistrationCombos">
                                        <div class="pricingTable">
                                            <h3 class="product-id" style="display: none;">{{ product.id }}</h3>
                                            <h3 class="title">{{ product.name }}</h3>
                                            <div class="price-value">
                                                <span class="amount">{{ product.price | currency:'R ':'symbol':'1.0'}}</span>
                                            </div>

                                            <ul class="pricing-content">
                                                <li><i class="fas fa-check"></i>{{ "Company Registration" }}</li>  <!-- (@sach) TODO: this is just hanging? -->
                                                <li *ngFor="let subProduct of product.subProducts"><i class="fas fa-check" ></i>{{ subProduct }}</li>
                                            </ul>
                                            <a class="btn btn-primary" routerLink='/customer-details' (click)="selectProduct($event)">Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>