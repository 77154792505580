<section class="cta-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Looking for exclusive services?</h4>
                <h2>Get The Best For Your Business</h2>
                <p>Our support team is always available to assist with your queries to help you and your organisation to get the best possible service. We can guide you in the right direction of choosing the correct package to suit your business speed needs and requirements.</p>
                <a href="#contact" class="btn btn-primary">Contact Us</a>
            </div>
        </div>
    </div>
</section>