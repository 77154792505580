<section id="payment-success" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Payment<span> Complete</span></h2>            
        </div>
        <section class="payment-success-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="cta-content">
                    <div class="section-title mb-0">
                        <div style="background-color: #188780;
                        text-align: center;
                        padding: 15px 0px 1px 0px;
                        margin: 30px 90px 30px 90px;
                        border-radius: 20px;
                        ">
                        <!-- TODO: make this notification toast effect rather -->
                            <h4
                        style="color: antiquewhite;">Your payment has been successfully processed</h4>
                        </div>
                        
                        <h2 
                        style="-webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #463231;;"
                        >Congratulations!</h2>
                        <p style="color: #463231;">Our support team is always available to assist with your queries to help you and your business to get the best possible service. We can guide you in the right direction of choosing the correct package to suit your business speed needs and requirements.</p>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <a style="margin: 5px;" href="#contact" class="btn btn-primary">Contact Us</a>
                        <button (click)="downloadInvoice()" class="btn btn-primary">Download Invoice</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>