<section id="checkout" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>Checkout</span></h2>
            <p>Your order is ready! Please find the details of your order below. If you are happy with this order,
                kindly make a payment to confirm your purchase.</p>
        </div>

        <form ngNoForm action="https://www.payfast.co.za/eng/process" 
                    method="post"> 
            <div class="row">
            
                    <!-- (@sach) TODO: these two columns should be the same height always -->
                     <div class="col-lg-7 col-md-12">
                    <!-- (@sach) TODO: this shouldnt be a form initially but rather static text then should they want to change something then they can do so using and "edit" button. -->
                
                    <div class="contact-form">
                        <!-- (@sach) TODO: remove the inline styling and move this to the main css file -->
                        <div class="section" style="margin-bottom: 30px;">
                            <h2><span>Billing Details</span></h2>
                        </div>

                          
                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="hidden" name="cmd" class="form-control" value="_paynow">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="hidden" name="receiver" class="form-control" value="14605008">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <!-- TODO: this item descritption needs to change, needs to be the items purchashed
                                rather the order number -->
                                <input type="hidden" name="item_name" class="form-control" [value]="shoppingCart[0].getName()">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="hidden" name="amount" class="form-control" [value]=shoppingCartTotal>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="hidden" name="item_description" class="form-control" value="">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="hidden" name="custom_str1" class="form-control" [value]="order.getId()">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <!-- TODO: https://sachcoza.atlassian.net/browse/OR-159 -->
                                <input type="hidden" name="return_url" class="form-control" value="https://www.onlineregister.co.za/payment-success">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <!-- TODO: https://sachcoza.atlassian.net/browse/OR-159 -->
                                <input type="hidden" name="cancel_url" class="form-control" value="https://www.onlineregister.co.za/payment-failed">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="hidden" name="notify_url" class="form-control" value="https://us-central1-fir-demo-f3039.cloudfunctions.net/payfast">
                            </div>
                        </div>
                    
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <!-- (@sach) TODO: this should be prepopulated from the customer details page, giving the customer
                                a change to change should the billing details be different from the registering party -->
                                <span>{{ user.getName() }} </span>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <!-- (@sach) TODO: this should be prepopulated from the customer details page, giving the customer
                                a change to change should the billing details be different from the registering party -->
                                <span>{{ user.getEmail() }}</span>
                            </div>
                        </div>

                         <!-- (@sach) TODO: maybe this should be better under the order details rather than the billing details.
                        consider moving this to the other side. -->
                        
                    </div>
                </div>
            
                <div class="col-lg-5 col-md-12">           
                    <div class="contact-info">
                        <!-- (@sach) TODO: remove the inline styling and move this to the main css file -->
                        <div class="section" style="margin-bottom: 30px;">
                            <h2><span>Order Summary</span></h2>
                        </div>     
                            <div class="d-table">
                                    <ul>
                                        <li *ngFor="let product of shoppingCart">
                                            <i class="fas fa-map-marker-alt"></i>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <span>{{ product.name }}</span>
                                                </div>
                                                <div class="col-lg-6 col-md-6" style="text-align: right;">
                                                    <!-- (@sach) TODO: align this to the end so that it's easy to see / accounting formats -->
                                                    <span> R {{ product.price }}</span>
                                                </div>
                                            </div>
                                            <p *ngFor="let subProduct of product.subProducts">{{ subProduct }}</p>
                                        </li>
                                        <hr>
                                        
                                    </ul>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <h2>
                                                Total
                                            </h2>
                                        </div>
                                        <div class="col-lg-6 col-md-6" style="text-align: right;">
                                        <h2> R{{ shoppingCartTotal }} </h2>
                                        </div>
                                    </div>
                            </div>
                        </div>
                </div>
            </div>
            <div
            class="col-lg-12 col-md-12"
            style="
            text-align: right;
            margin-top: 20px;
            ">
                <button type="submit" class="btn btn-primary">Pay Now</button>
            </div>
        </form>
    </div>
</section>