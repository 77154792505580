<section class="blog-details-area ptb-100">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-6">
                <div class="blog-details">
                    <div class="article-img">
                        <img style="border-radius: 10px;" src="https://picsum.photos/760/300?random=43" alt="blog-details">
                    </div>
                    
                    <div style="margin-bottom: 20px;"class="article-text">
                        <!-- TODO: add tags -->
                        <!-- <ul style="margin-bottom: 20px;" class="category">
                            <li><a href="#">Boutique</a></li>
                            <li><a href="#">Retail</a></li>
                        </ul> -->
                        <h2 style="margin-bottom: 10px;"><strong>{{ product.name }}</strong></h2>
        
                        <h3 
                            style="
                            color: hotpink;
                            "><strong>R {{ product.price }}</strong></h3>
                        <p>{{ product.location }}</p>
        
                        <h5 style="margin-bottom: 5px;"><strong>Business Description</strong></h5>
                        <h6 style="
                        color: hotpink;
                        margin-bottom: 10px;
                        ">{{ product.subTitle }}</h6>
        
                        <p>
                            {{ product.description}}
                        </p>
                        </div>
                </div>
        
                <!-- <div class="blog-details"> -->
                    <!-- <div class="article-text">                            -->
                        <!-- <h5 style="margin-bottom: 5px;"><strong>Detailed Information</strong></h5> -->
                        <!-- <table> -->
                            <!-- <th></th> -->      <!-- Icon -->
                            <!--<th></th> -->       <!-- Heading-->
                            <!-- <th></th>-->       <!-- Description --> 
        
                            <!-- Location -->
                            <!-- <tr>
                                <td><i class="fas fa-trash"></i></td>
                                <td><strong>Location:</strong></td>
                                <td>Randburg, Kyalami; Gauteng</td>
                            </tr> -->
        
                            <!-- Sale Type -->
                            <!-- <tr>
                                <td><i class="fas fa-trash"></i></td>
                                <td><strong>Sale Type:</strong></td>
                                <td>Lock, Stock & Barrel including Company</td>
                            </tr> -->
        
                            <!-- Other Details -->
                            <!-- <tr>
                                <td><i class="fas fa-trash"></i></td>
                                <td><strong>Net Profit:</strong></td>
                                <td>R400,000.00 – R600,000.00</td>
                            </tr> -->
        
                            <!-- <tr>
                                <td><i class="fas fa-trash"></i></td>
                                <td><strong>Turnover:</strong></td>
                                <td>~R2,500,000</td>
                            </tr> -->
        
                            <!-- <tr>
                                <td><i class="fas fa-trash"></i></td>
                                <td><strong>Inventories:</strong></td>
                                <td>R500,000</td>
                            </tr> -->
                        <!-- </table> -->
                    <!-- </div> -->
                <!-- </div> -->
            </div>
        
            <div class="leave-a-reply col-lg-4 col-md-6">
                <h3 class="title mt-0">Leave a Reply</h3>
                <p style="margin-bottom: 20px ;">Get in touch with a consultant</p>
                <form #f="ngForm" (ngSubmit)="submit(f.form)">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input ngModel
                                type="text" class="form-control" name="name" placeholder="Full Name">
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input ngModel type="email" class="form-control" name="email" placeholder="E-Mail">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <!-- <div *ngIf='phone.touched && !phone.valid' class="alert alert-danger">Please enter a valid phone number</div> -->
                                <!-- (@sach) TODO: https://sachcoza.atlassian.net/browse/OR-115 -->
                                <input
                                required
                                minlength="10"
                                pattern="^[0-9+ ]*$"
                                ngModel name="phone"
                                type="text"
                                class="form-control"
                                #phone='ngModel'
                                placeholder="Phone">
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea ngModel name="message" cols="30" rows="5" class="form-control" placeholder="Hello,I'd like to know more about this business"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>