import { Component } from '@angular/core';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { Enquiry } from 'src/app/models/enquiry/enquiry';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent{

  enquiry : Enquiry;

  constructor(private service : EnquiryService) { }

  ngOnInit() {
  }

  submit(f){
    this.enquiry = new Enquiry(
      f.value.name,
      f.value.email,
      f.value.phone,
      f.value.title,
      f.value.message);
    this.service.setEnquiryDetails(this.enquiry);
    // this.router.navigate(['/enquirysuccess']); // send to enquiry submitted page
    // clear the form
    f.reset();
}
}
