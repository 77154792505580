export class User {

    private id: string;
    private name: string;
    private role: string;
    private nationalRegNo : string;
    private idType: string
    private email : string;
    private phone : string;

    constructor (
        name : string,
        idType : string,
        nationalRegNo : string,
        email : string,
        phone : string
        ){
        this.id = null;
        this.name = name;
        this.idType = idType;
        this.nationalRegNo = nationalRegNo;
        this.email = email;
        this.phone = phone;
        }

        // (OR-32, @sach) TODO: might need to remove this because database should assign this
        setId(id : string){
            this.id = id;
        }

        setIdType(_idType : string){
            this.idType = _idType;
        }
        
        setNationalRegNo(_nationalRegNo : string){
            this.nationalRegNo = _nationalRegNo;
        }
    
        setName(_name : string) {
            this.name = _name;
        }

        setRole(_role : string){
            this.role = _role;
        }

        setEmail(_email : string){
            this.email = _email;
        }

        setPhone(phone : string){
            this.phone = phone;
        }

        getName() : string {
            return this.name;
        }

        getRole() : string {
            return this.role;
        }

        getId() : string{
            return this.id;
        }

        getidType() : string{
            return this.idType;
        }
        
        getNationalRegNo() : string{
            return this.nationalRegNo;
        }

        getEmail(){
            return this.email;
        }
         getPhone(){
             return this.phone;
         }

        getData(){
            return {
                id : this.id,
                name: this.name,
                idType : this.idType,
                nationalRegNo: this.nationalRegNo,
                email: this.email,
                phone: this.phone
            }
        }

}