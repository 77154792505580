<div class="main-banner item-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <!-- (@sach) TODO: make this text bigger, also should this really be here? <h4>We Are Creative</h4> -->
                    <h1>We Are 
                        <a href="" class="typewrite" data-period="2000" data-type='[ "Professional", "Passionate", "Efficient", "Online Register"]'>
                        <span class="wrap"></span>
                        </a>
                    </h1>
                    <h3>South Africa's Leading Business Registration Portal & Marketplace!</h3>
                    <!-- (@sach) TODO: make this text bigger --> <p>Start your company today in under 15 minutes!</p>
                    <a style="margin-bottom: 10px;" href="#pricing" class="btn btn-primary">Register New Company</a>
                    <!-- (@sach) TODO: routing error coming up in console -->
                    <a style="margin-bottom: 10px;" class="btn btn-primary" routerLink='/marketplace'>Buy Shelf Company</a>
                    <a style="margin-bottom: 10px;" class="btn btn-primary" routerLink='/biz'>Businesses For Sale</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-about></app-about>

<app-who-we-are></app-who-we-are>

<app-services-two></app-services-two>

<app-pricing></app-pricing>

<app-cta></app-cta>

<app-faq></app-faq>

<app-contact></app-contact>