<section id="order-summary" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>Order</span> Summary</h2>
            <p>Almost done! These are the products that you have selected. If you are happy with your selection,
                please proceed to checkout.</p>
        </div>

        <!-- create a div to give feedback to the user if they have removed all products, 
            give them a link to redirect them to the marketplace -->

        <div class="col-lg-12 col-md-12">           
        <div class="contact-info" style="margin-bottom: 30px;">
            <!-- (@sach) TODO: remove the inline styling and move this to the main css file --> 
                <div>
                    <!-- center this text also move this css to style sheet, need a no products icon here -->
                    <span *ngIf="!hasProducts" style="text-align: center;">
                        No products in shopping cart!
                    </span>
                </div>
                <div *ngIf="hasProducts" class="d-table">
                        <ul>
                            <li *ngFor="let product of shoppingCart">
                                <i [id]="product.getId() + 'DelBtn'" class="fas fa-trash" (click)="delete($event)"></i>
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <span>{{ product.name }}</span>
                                    </div>
                                    <div class="col-lg-6 col-md-12" style="text-align: right;">
                                        <!-- (@sach) TODO: align this to the end so that it's easy to see / accounting formats -->
                                        <span>{{ product.price | currency:'R ':'symbol':'1.0'  }}</span>
                                        <!-- there has to be a better way for this selection man ugh -->
                                        <!-- align this trash can properly -->
                                    </div>
                                </div>
                                <p *ngFor="let subProduct of product.subProducts">{{ subProduct }}</p>
                            </li>
                            <hr>
                            
                        </ul>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <h2>
                                    Total
                                </h2>
                            </div>
                            <div class="col-lg-6 col-md-6" style="text-align: right;">
                            <h2>{{ shoppingCartTotal | currency:'R ':'symbol':'1.0'  }} </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="hasProducts" class="row">
                    <!-- need a back button -->
                    <!-- <div class="col-lg-12 col-md-12">
                        <button class="btn btn-primary">Confirm Order</button>
                    </div> -->
                    <div class="col-lg-12 col-md-12">
                        <button class="btn btn-primary" routerLink='/checkout' (click)="placeOrder()">Confirm Order</button>
                    </div>
                </div>
            </div>
    </div>
</section>