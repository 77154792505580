import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { AboutComponent } from './components/common/about/about.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeComponent } from './components/home/home.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { ShoppingCartService } from './services/shopping-cart/shopping-cart.service';
import { FormsModule }   from '@angular/forms';
import { UserDetailsService } from './services/user-details/user-details.service';
import { PaymentService } from './services/payment/payment.service';
import { HttpClientModule } from '@angular/common/http';
import { ProductService } from './services/product/product.service';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success/payment-success.component';
import { EnquiryService } from './services/enquiry/enquiry.service';
import { OrderSummaryComponent } from './components/order-summary/order-summary/order-summary.component';
import { BizMarketComponent } from './components/biz-market/biz-market.component';
import { TruncatePipe } from './pipes/truncate/truncate';
import { BizProductDetailComponent } from './components/biz-market/biz-product-detail/product-detail/product-detail.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        AboutComponent,
        WhoWeAreComponent,
        CtaComponent,
        FunfactsComponent,
        FooterComponent,
        FaqComponent,
        PricingComponent,
        ContactComponent,
        ServicesTwoComponent,
        HomeComponent,
        BlogDetailsComponent,
        MarketplaceComponent,
        CheckoutComponent,
        CheckoutComponent,
        CustomerDetailsComponent,
        PaymentFailedComponent,
        PaymentSuccessComponent,
        OrderSummaryComponent,
        BizMarketComponent,
        BizProductDetailComponent,
        TruncatePipe,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        FormsModule
    ],
    providers: [
        ShoppingCartService,
        UserDetailsService,
        PaymentService,
        ProductService,
        EnquiryService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }